import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  HashRouter,
} from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import TemplateGenerator from "./dashboard/TemplateGenerator";
import TemplateList from "./dashboard/TemplateList";
import TemplateEditor from "./dashboard/TemplateEditor";
import Navbar from "./layout/Navbar";
import Login from "./Auth/Login";
import AuthProvider from "./Auth/AuthContext"; // to check why no bracket
import Signup from "./Auth/Signup";
import PrivateRoute from "./layout/PrivateRoute";
import ForgotPassword from "./Auth/ForgotPassword";
//import NotFound from "./layout/NotFound";

export default class App extends Component {
  render() {
    return (
      <div className="App">
        {/* <Router basename="/StatusReportApp"> */}
        <Router>
          <HashRouter>
            <AuthProvider>
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/signup" component={Signup} />
                <Route path="/forgot-password" component={ForgotPassword} />
                {/* <Route path="*" component={NotFound} /> */}

                <React.Fragment>
                  <Navbar />

                  <PrivateRoute exact path="/" component={Dashboard} />

                  <PrivateRoute
                    path="/create-template"
                    component={TemplateGenerator}
                  />

                  <PrivateRoute
                    path="/template-list"
                    component={TemplateList}
                  />

                  <PrivateRoute
                    path="/template-editor/:id"
                    component={TemplateEditor}
                  />

                  <PrivateRoute path="/dashboard" component={Dashboard} />
                </React.Fragment>
              </Switch>
            </AuthProvider>
          </HashRouter>
        </Router>
      </div>
    );
  }
}
