import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../Auth/AuthContext";
import { useHistory } from "react-router";

function VariableDropDown({ childToParent }) {
  // const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_ENDPOINT =
    "https://apps.lasalle.edu.sg/ProofofStudentStatusLetterAPI/api/StudMgtSys";
  //const API_ENDPOINT = "https://localhost:44384/api/StudMgtSys";
  // const API_ENDPOINT =
  //   "https://appsuat.lasalle.edu.sg/ProofofStudentStatusLetterAPI/api/StudMgtSys";

  const [variables, setvariables] = useState([]);
  const [value, setValue] = React.useState("ABC");
  const { currentUser } = useAuth();
  const history = useHistory();
  const { logout, googleLogout } = useAuth();

  let token;

  if (currentUser) {
    token = currentUser.Aa; //AccessToken
  }

  useEffect(
    function () {
      axios
        .get(
          //`https://appsuat.lasalle.edu.sg/StatusReportAPI/api/StudMgtSys/TemplateVariable`, //UAT
          `${API_ENDPOINT}/TemplateVariable`,
          {
            headers: {
              Authorization: `Bearer  ${token} `,
            },
          }
        )
        .then((res) => {
          setvariables(res.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            //redirect to logout in reinitiate user session
            googleLogout();
            history.push("/login");
          } else {
            console.log(error.response);
          }
        });
    },

    []
  );

  return (
    <select
      className="form-control"
      onChange={(e) => childToParent(e.target.value)}
    >
      <option value="0"> ---- Select Variable ----</option>
      {variables &&
        variables.map((item) => (
          <option key={item.systemId} value={item.variableName}>
            {item.variableName}
          </option>
        ))}
    </select>
  );
}

export default VariableDropDown;
