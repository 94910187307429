import React, { useContext, useState, useEffect } from "react";
import { auth } from "./firebase";
import firebase from "firebase/app";
import axios from "axios";

import { useHistory } from "react-router-dom";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  // const API_ENDPOINT =
  //   "https://appsuat.lasalle.edu.sg/ProofofStudentStatusLetterAPI/api/StudMgtSys";

  // const API_ENDPOINT = "https://localhost:44384/api/StudMgtSys";

  const API_ENDPOINT =
    "https://apps.lasalle.edu.sg/ProofofStudentStatusLetterAPI/api/StudMgtSys"; //prod

  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  function googleLogin() {
    var provider = new firebase.auth.GoogleAuthProvider();
    return firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        //var credential = result.credential;
        //var token = credential.idToken;
        var user = result.user;
        var username = user.email.split("@")[0];

        //Lasalle User
        if (user.email.split("@")[1] !== "lasalle.edu.sg") {
          googleLogout();
        }

        localStorage.setItem("usernamefromlocal", username);

        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            axios
              .get(`${API_ENDPOINT}/User/SearchUserbyName?name=` + username, {
                headers: {
                  Authorization: `Bearer  ${idToken} `,
                },
              })
              .then((res) => {
                localStorage.setItem("userrolefromlocal", res.data.roleId);
                localStorage.setItem("usergroupfromlocal", res.data.userGroup);
                //localStorage.setItem("userrolefromlocal", res.data.roleId);
                history.push("/");
              })
              .catch((error) => {
                console.log(error.response);
                if (error.response.status === 401) {
                  console.log(error.response);
                  googleLogout();
                  //history.push("/login");
                } else {
                  console.log(error.response);
                  googleLogout();
                }
              });
          })
          .catch(function (error) {
            // Handle error
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function googleLogout() {
    return firebase
      .auth()
      .signOut()

      .then(
        function () {
          localStorage.removeItem("usernamefromlocal");
        },
        function (error) {
          console.log("Signout Failed");
        }
      );
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signup,
    login,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    googleLogin,
    googleLogout,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
