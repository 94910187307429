import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../Auth/AuthContext";
import { useHistory } from "react-router";
import Select from "react-select";

function UserGroupDropDown({ childToParentUsrGroup }) {
  // const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_ENDPOINT =
    "https://apps.lasalle.edu.sg/ProofofStudentStatusLetterAPI/api/StudMgtSys";
  //const API_ENDPOINT = "https://localhost:44384/api/StudMgtSys";
  // const API_ENDPOINT =
  //   "https://appsuat.lasalle.edu.sg/ProofofStudentStatusLetterAPI/api/StudMgtSys";

  const [userGroup, setUserGroup] = useState([]);
  // const [value, setValue] = React.useState("ABC");
  const { currentUser } = useAuth();
  const history = useHistory();
  const { logout, googleLogout } = useAuth();

  let token;

  if (currentUser) {
    token = currentUser.Aa; //AccessToken
  }

  let userGroupOption =
    userGroup &&
    userGroup.map((item) => ({
      label: item.appUserGroupName,
      value: item.appUserGroupId,
      //isFixed: item.systemId == 1 ? true : false, //to remove
    }));
  console.log("userGroupOption" + userGroupOption);
  useEffect(
    function () {
      axios
        .get(
          //`https://appsuat.lasalle.edu.sg/StatusReportAPI/api/StudMgtSys/TemplateVariable`, //UAT
          `${API_ENDPOINT}/AppUserGroup`,
          {
            headers: {
              Authorization: `Bearer  ${token} `,
            },
          }
        )
        .then((res) => {
          //console.log(res.data);
          setUserGroup(res.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            //redirect to logout in reinitiate user session
            googleLogout();
            history.push("/login");
          } else {
            console.log(error.response);
          }
        });
    },

    []
  );

  const handleSelect = (data) => {
    //Add Master Group Automatically
    const usrData = [1];
    data.forEach((data) => {
      usrData.push(data.value);
    });

    childToParentUsrGroup(usrData);
  };

  return (
    <Select
      isMulti
      // value={test}
      name="userGroupOpt"
      options={userGroupOption}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={handleSelect}
    />
  );
}

export default UserGroupDropDown;
