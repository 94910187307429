import React from "react";
import SignedInLinks from "./SignedInLinks";

export default function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark ">
      <div className="container-fluid ">
        {/* <a className="navbar-brand" href="/StatusReportApp"> */}
        <a className="navbar-brand" href="/">
          {" "}
          <img src="images/LASALLE_logo_white.svg" width="300" alt="Lasalle" />
        </a>
        <SignedInLinks />
      </div>
    </nav>
  );
}
