import firebase from "firebase/app";
import "firebase/auth";

// const app = firebase.initializeApp({
//   apiKey: "AIzaSyDKIi-s-TllBhDkIwmRMS6afSmug4G5LEg",
//   authDomain: "status-report-dev.firebaseapp.com",
//   projectId: "status-report-dev",
//   storageBucket: "status-report-dev.appspot.com",
//   messagingSenderId: "240977922467",
//   appId: "1:240977922467:web:53d465ce927c9bbf257216",
//   measurementId: "G-941HLM3WMG",
// });

// const app = firebase.initializeApp({
//   apiKey: "AIzaSyBl7VkY9kOHDowqcmuVL6gFEMN1xO07klo",
//   authDomain: "portalauthenticationapi.firebaseapp.com",
//   projectId: "portalauthenticationapi",
//   storageBucket: "portalauthenticationapi.appspot.com",
//   messagingSenderId: "799466154086",
//   appId: "1:799466154086:web:df036a9cf32c387817e5a7",
//   measurementId: "G-DNV5S74QW5",
// });

// const app = firebase.initializeApp({
//   apiKey: "AIzaSyABM9LgM5Fwh5D9OE2mI7y-_HgZwqifhmQ",
//   authDomain: "proofofstudentstatusletter-uat.firebaseapp.com",
//   projectId: "proofofstudentstatusletter-uat",
//   storageBucket: "proofofstudentstatusletter-uat.appspot.com",
//   messagingSenderId: "50949740908",
//   appId: "1:50949740908:web:98fe4335e1cdf6b6ff3b49",
//   measurementId: "G-6YX70P1SNZ",
// });

const app = firebase.initializeApp({
  apiKey: "AIzaSyBhar0uJTDjpVAvJXZxuHePUJj-KArBj1E",
  authDomain: "proofofstudentstatusletter.firebaseapp.com",
  projectId: "proofofstudentstatusletter",
  storageBucket: "proofofstudentstatusletter.appspot.com",
  messagingSenderId: "875538152453",
  appId: "1:875538152453:web:b8c314148bafb1b4a2306c",
  measurementId: "G-BC4BJZJYCC",
});

export const auth = app.auth();
export default app;
