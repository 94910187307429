import React, { useRef, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useAuth } from "./AuthContext";
import { Link, useHistory } from "react-router-dom";

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login, googleLogin } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setLoading(true);
      setError("");
      await login(emailRef.current.value, passwordRef.current.value);
      setLoading(false);
      history.push("/");
    } catch (err) {
      return setError("Fail to log in");
    }
  }

  async function googleHandle() {
    try {
      await googleLogin();
    } catch (e) {
      console.log(e + "Failed to login using google");
    }
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-3 "></div>

          <div className="col-sm-6" align="center">
            <div className="item-centered">
              <img
                src="images/LASALLE_logo_black.svg"
                alt="Lasalle"
                height="100"
              />
            </div>

            <h2>Proof of Student Status Letters System</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            {/* <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Form.Group id="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              <br />
              <Button disabled={loading} className="w-100" type="submit">
                Log In
              </Button>
              <div className="w-100 text-center mt-3">
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
              <br />
              <div align="center">
                <img
                  src="images/btn_google_signin_dark_pressed_web.png"
                  height="50"
                  alt="Singin with Google"
                  onClick={googleHandle}
                />
              </div>
            </Form> */}
            <Form className="google-login-form" onSubmit={handleSubmit}>
              {/* <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Form.Group id="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              <br />
              <Button disabled={loading} className="w-100" type="submit">
                Log In
              </Button>
              <div className="w-100 text-center mt-3">
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
              <br /> */}
              <div align="center">
                <br />
                <h5>Log in using your account on:</h5>
                <br />
                <img
                  src="images/btn_google_signin_dark_pressed_web.png"
                  height="50"
                  alt="Singin with Google"
                  onClick={googleHandle}
                />
              </div>
              <br />
              <div align="center">
                <i>*Please turn on the VPN to access the site</i>
              </div>
            </Form>
            {/* <div className="w-100 text-center mt-2">
              Need an account? <Link to="/signup">Sign Up</Link>
            </div> */}
          </div>
          <div className="col-sm-3 "></div>
        </div>
      </div>
    </>
  );
}
