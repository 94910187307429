import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Card } from "react-bootstrap";
import axios from "axios";
import VariableDropDown from "./VariableDropDown";
import UserGroupDropDown from "./UserGroupDropDown";
import { useAuth } from "../Auth/AuthContext";
import { useHistory } from "react-router";

export default function TemplateGenerator() {
  // const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_ENDPOINT =
    "https://apps.lasalle.edu.sg/ProofofStudentStatusLetterAPI/api/StudMgtSys";
  //const API_ENDPOINT = "https://localhost:44384/api/StudMgtSys";
  // const API_ENDPOINT =
  //   "https://appsuat.lasalle.edu.sg/ProofofStudentStatusLetterAPI/api/StudMgtSys";
  const [templates, setTemplates] = useState(null);
  const [data, setData] = useState("");
  const [templatename, setTemplatename] = useState("");
  const [remark, setRemark] = useState("");
  const [userGroup, setUserGroup] = useState(1);
  const [message, setMessage] = useState("");
  const { currentUser } = useAuth();
  const history = useHistory();
  const { logout, googleLogout } = useAuth();

  let token;
  let urole = localStorage.getItem("userrolefromlocal");
  if (currentUser) {
    token = currentUser.Aa; //AccessToken
  }

  useEffect(() => {
    let unmounted = false;

    //Handle User Role Checking - to make dynamic

    if (urole != 1) {
      history.push("/");
    }

    return () => {
      unmounted = true;
    };
  }, [urole]);

  const handleEditorChange = (e) => {
    setTemplates(e.target.getContent());
  };

  const handleClick = () => {
    if (templatename.includes("&")) {
      setMessage("Error : & character is not allowed in Template Name.");
    } else if (templatename == "") {
      setMessage("Error : Missing Template Name.");
    } else if (userGroup == "") {
      setMessage("Error : Missing User Group.");
    } else {
      setMessage("");
      let ugroup = JSON.stringify(userGroup);
      ugroup = ugroup.replace(/[\[\]']+/g, "");

      let payload = {
        templateName: templatename,
        templateContent: templates,
        remarks: remark,
        userGroup: ugroup,
        createdBy: currentUser.displayName, // to take from login
      };

      axios
        .post(
          //`https://appsuat.lasalle.edu.sg/StatusReportAPI/api/StudMgtSys/ReportTemplate/AddReportTemplate`,
          `${API_ENDPOINT}/ReportTemplate/AddReportTemplate`,
          payload,
          {
            headers: {
              Authorization: `Bearer  ${token} `,
            },
          }
        )
        .then((res) => {
          // console.log(res);
          // console.log(res.data);
          setMessage("Template Added Successfully. ");
        })
        .catch((error) => {
          if (error.response.status === 401) {
            //redirect to logout in reinitiate user session
            googleLogout();
            history.push("/login");
          } else {
            // console.log(error.response);
            setMessage("Error Adding Template. " + error.response.data);
          }
        });
    }
  };

  const childToParent = (childdata) => {
    setData(childdata);
    if (childdata != "") {
      var customdata = "{{" + childdata + "}}";
      window.tinyMCE.activeEditor.insertContent(customdata);
    }
  };

  const childToParentUsrGroup = (childdata) => {
    setUserGroup(childdata);
  };

  return (
    <>
      <Card>
        <Card.Header>
          <h4 className="text-center mb-1"> Add New Template</h4>
        </Card.Header>

        <Card.Body>
          <div className="row">
            <div className="col">
              {" "}
              <label>Template Name*:</label>
              <input
                className="form-control"
                type="text"
                name="templatename"
                value={templatename}
                onChange={(e) => setTemplatename(e.target.value)}
              />
            </div>
            <div className="col">
              {" "}
              <label>Variable:</label>
              <VariableDropDown childToParent={childToParent} />
            </div>
          </div>

          <Editor
            apiKey="1jjyk6sqglfhmktn8b1czmlrs6ynn0dffj9qrl3xotrvbpdc"
            init={{
              height: 500,
              deprecation_warnings: false,
              content_style: "p { margin: 0; }",
              file_picker_types: "image",
              file_picker_callback: function (cb, value, meta) {
                var input = document.createElement("input");
                input.setAttribute("type", "file");
                input.setAttribute("accept", "image/*");

                input.onchange = function () {
                  var file = this.files[0];

                  var reader = new FileReader();
                  reader.onload = function () {
                    var id = "blobid" + new Date().getTime();
                    var blobCache =
                      window.tinyMCE.activeEditor.editorUpload.blobCache;
                    var base64 = reader.result.split(",")[1];
                    var blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);

                    cb(blobInfo.blobUri(), { title: file.name });
                  };
                  reader.readAsDataURL(file);
                };

                input.click();
              },
            }}
            onChange={handleEditorChange}
            plugins="print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons"
            menubar="file edit view insert format tools table help"
            toolbar="undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl"
          />
          <br />

          <div className="row">
            <div className="col">
              <div className="form-group">
                <div className="col">
                  {" "}
                  <label>User Group</label>
                  <UserGroupDropDown
                    childToParentUsrGroup={childToParentUsrGroup}
                  />
                </div>
                <label htmlFor="remark">Remark</label>
                <textarea
                  className="form-control"
                  id="remark"
                  rows="1"
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                ></textarea>
              </div>
              <div className="d-grid gap-2 col-2 mx-auto">
                <button className="btn btn-primary mt-3" onClick={handleClick}>
                  {" "}
                  Save{" "}
                </button>
              </div>
            </div>
          </div>
          {message && message.includes("Error") ? (
            <div className="message">
              {" "}
              <div style={{ color: "red", fontWeight: "bold" }}>{message} </div>
            </div>
          ) : (
            <div className="message">{message}</div>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
