import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../Auth/AuthContext";
import { useHistory } from "react-router";
import axios from "axios";

const SignedInLinks = () => {
  const history = useHistory();
  const { logout, googleLogout } = useAuth();
  const { currentUser } = useAuth();
  const [menuItems, setMenuItems] = useState([]);

  let urole = localStorage.getItem("userrolefromlocal");

  const API_ENDPOINT =
    "https://apps.lasalle.edu.sg/ProofofStudentStatusLetterAPI/api/StudMgtSys";

  //const API_ENDPOINT = "https://localhost:44384/api/StudMgtSys";
  // const API_ENDPOINT =
  //   "https://appsuat.lasalle.edu.sg/ProofofStudentStatusLetterAPI/api/StudMgtSys";

  let token;

  if (currentUser) {
    token = currentUser.Aa; //AccessToken
  }

  useEffect(function () {
    axios
      .get(
        `${API_ENDPOINT}/RoleAccessRight/SearchRoleAccessRightbyRoleID?id=` +
          urole,
        {
          headers: {
            Authorization: `Bearer  ${token} `,
          },
        }
      )
      .then((res) => {
        res.data.sort((a, b) => a.menuDisplaySequence - b.menuDisplaySequence);
        setMenuItems(res.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          googleLogout();
          history.push("/login");
        } else {
          console.log(error.response);
        }
      });
  }, []);

  async function handleClick() {
    try {
      await googleLogout();
      history.push("/login");
    } catch {
      console.log("Failed to Logout using google");
    }
  }
  return (
    // <ul className="navbar-nav ms-auto mb-2 mb-lg-0 ">
    //   <li className="no-bullets nav-item">
    //     <NavLink className="navCustom" to="/create-template">
    //       {" "}
    //       New Template{" "}

    //     </NavLink>
    // </li>
    //   <li className="no-bullets nav-item">
    //     <NavLink className="navCustom" to="/template-list">
    //       {" "}
    //       Template List{" "}
    //     </NavLink>
    // </li>
    //   <li className="no-bullets nav-item">
    //     <NavLink className="btn navBtnCustom" to="" onClick={handleClick}>
    //       {" "}
    //       Logout{" "}
    //     </NavLink>
    //   </li>
    // </ul>

    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
      {menuItems &&
        menuItems.map((menuItem) => {
          const { menuDisplaySequence, menuPath, menuName } = menuItem;
          if (menuDisplaySequence === 4) {
            return (
              <li className="no-bullets nav-item" key={menuDisplaySequence}>
                <NavLink
                  className="btn navBtnCustom"
                  to=""
                  onClick={handleClick}
                >
                  {" "}
                  Logout
                </NavLink>
              </li>
            );
          } else {
            return (
              <li className="no-bullets nav-item" key={menuDisplaySequence}>
                <NavLink className="navCustom" to={menuPath}>
                  {menuName}
                </NavLink>
              </li>
            );
          }
        })}
    </ul>
  );
};

export default SignedInLinks;
