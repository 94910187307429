import React, { useRef, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useAuth } from "./AuthContext";
import { Link, useHistory } from "react-router-dom";

export default function Signup() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState("");
  // const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Password do not match");
    }
    try {
      //setLoading(true);
      setError("");
      await signup(emailRef.current.value, passwordRef.current.value);
      //setLoading(false);
      history.push("/");
    } catch (err) {
      return setError(err);
    }
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-3 "></div>
          <div className="col-sm-6 " align="center">
            <div className="item-centered">
              <img
                src="images/lasalle-logo-black.png"
                alt="Lasalle"
                height="100"
              />
            </div>
            <h2>Proof of Student Status Letters System</h2>{" "}
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>

              <Form.Group id="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>

              <Form.Group id="password-confirm">
                <Form.Label>Password Confirmation</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordConfirmRef}
                  required
                />
              </Form.Group>
              <br />
              <Button disabled={true} className="w-100" type="submit">
                {/* <Button disabled={loading} className="w-100" type="submit"> */}
                Sign Up
              </Button>
            </Form>
            <div className="w-100 text-center mt-2">
              Already have an account?<Link to="/login">Log In</Link>
            </div>
          </div>
          <div className="col-sm-3 "></div>
        </div>
      </div>
    </>
  );
}
