import React, { useRef, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setLoading(true);
      setError("");
      await resetPassword(emailRef.current.value);
      setMessage("Check your inbox for futher instructions");
    } catch {
      return setError("Fail to log in");
    }
    setLoading(false);
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-3 "></div>

          <div className="col-sm-6" align="center">
            <div className="item-centered">
              <img
                src="images/lasalle-logo-black.png"
                alt="Lasalle"
                height="100"
              />
            </div>

            <h2>Proof of Student Status Letters System</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            {message && <Alert variant="success">{message}</Alert>}

            <Form className="custom-form" onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>

              <br />
              <Button disabled={loading} className="w-100" type="submit">
                Rest Password
              </Button>

              <div className="w-100 text-center mt-3">
                <Link to="/login">Login</Link>
              </div>
              <div className="w-100 text-center mt-2">
                Need an account? <Link to="/signup">Sign Up</Link>
              </div>
            </Form>
          </div>
          <div className="col-sm-3 "></div>
        </div>
      </div>
    </>
  );
}
