import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { useAuth } from "../Auth/AuthContext";
import { useHistory } from "react-router";

export default function TemplateViewer() {
  //const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_ENDPOINT =
    "https://apps.lasalle.edu.sg/ProofofStudentStatusLetterAPI/api/StudMgtSys";
  //const API_ENDPOINT = "https://localhost:44384/api/StudMgtSys";
  // const API_ENDPOINT =
  //   "https://appsuat.lasalle.edu.sg/ProofofStudentStatusLetterAPI/api/StudMgtSys";

  const [data, setData] = useState(null);
  const [content, setContent] = useState(null);
  const [message, setMessage] = useState("");
  const { currentUser } = useAuth();
  const history = useHistory();
  const { logout, googleLogout } = useAuth();

  let token;
  let urole = localStorage.getItem("userrolefromlocal");
  let ugroup = localStorage.getItem("usergroupfromlocal");

  if (currentUser) {
    token = currentUser.Aa; //AccessToken
  }

  useEffect(function () {
    let mounted = true;

    //Handle User Role Checking - to make dynamic
    if (urole != 1) {
      history.push("/");
    }

    console.log("ugroup" + ugroup);

    if (ugroup == "" || ugroup == null) {
      ugroup = 0;
    } else {
      axios
        .get(
          //`https://appsuat.lasalle.edu.sg/StatusReportAPI/api/StudMgtSys/ReportTemplate`,
          // `${API_ENDPOINT}/ReportTemplate`,
          `${API_ENDPOINT}/ReportTemplate/SearchTemplatebyUserGroup?usergroup_id=` +
            ugroup,
          {
            headers: {
              Authorization: `Bearer  ${token} `,
            },
          }
        )
        .then((res) => {
          if (mounted) {
            setData(res.data);
            // console.log(res.data);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            //redirect to logout in reinitiate user session
            googleLogout();
            history.push("/login");
          } else {
            //console.log(error.response);
            setMessage("Error calling API " + error.response);
          }
        });
    }

    return () => (mounted = false);
  }, []);

  const handleClick = (template) => {
    axios
      .get(
        // `https://appsuat.lasalle.edu.sg/StatusReportAPI/api/StudMgtSys/ReportTemplate/SearchTemplatebyID?id=` +
        `${API_ENDPOINT}/ReportTemplate/SearchTemplatebyID?id=` +
          template.systemId,
        {
          headers: {
            Authorization: `Bearer  ${token} `,
          },
        }
      )
      .then((res) => {
        const data = res.data;
        //console.log("data " + JSON.stringify(data));
        setContent(data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //redirect to logout in reinitiate user session
          googleLogout();
          history.push("/login");
        } else {
          console.log(error.response);
          setMessage("Error calling API " + error.response);
        }
      });
  };

  const handleDelete = (systemId) => {
    if (window.confirm("Are you sure you wish to delete this item?")) {
      // Update API
      axios
        .delete(
          //`https://appsuat.lasalle.edu.sg/StatusReportAPI/api/StudMgtSys/ReportTemplate/DeleteReportTemplate?sysID=` +
          `${API_ENDPOINT}/ReportTemplate/DeleteReportTemplate?sysID=` +
            systemId,
          {
            headers: {
              Authorization: `Bearer  ${token} `,
            },
          }
        )
        .then((res) => {
          //console.log(res.data);
          const remainingResults = data.filter(
            (result) => result.systemId !== systemId
          );
          setData(remainingResults);
          setContent(null);
          setMessage("Delete Successfully.");
        })
        .catch((error) => {
          if (error.response.status === 401) {
            //redirect to logout in reinitiate user session
            googleLogout();
            history.push("/login");
          } else {
            console.log(error.response);
            setMessage("Error Deleting template. " + error.response);
          }
        });
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="card-header">
              <h4 className="text-center mb-1">Template List</h4>
            </div>
            <br />
            <div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th className="w-50">Template Name</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {data &&
                    data.map((template, index) => (
                      <tr key={template.systemId} value={template.systemId}>
                        <td>{index + 1}</td>
                        <td>{template.templateName}</td>
                        <td align="center">
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => handleClick(template)}
                          >
                            <i className="far fa-eye"></i>
                          </button>{" "}
                          {/* <Link to={"/template-editor/" + template.systemId}> */}
                          <Link
                            to={{
                              pathname: "/template-editor/" + template.systemId,
                              templateContent: template.templateContent,
                              templateName: template.templateName,
                              remark: template.remarks,
                              userGroup: template.userGroup,
                            }}
                          >
                            <button className="btn btn-success btn-sm">
                              {" "}
                              <i className="fas fa-edit"></i>
                            </button>
                          </Link>{" "}
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleDelete(template.systemId)}
                          >
                            <i className="far fa-trash-alt"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {message && <p className="message"> {message} </p>}
            </div>
          </div>
          <div className="col-md-8">
            <div className="card-header">
              <h4 className="text-center mb-1">Preview</h4>
            </div>
            <br />
            {/* To load the selected dynamic template content */}
            <div>
              {content && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: content.templateContent,
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
