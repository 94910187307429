import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from "../Auth/AuthContext";
import { useHistory } from "react-router";
import EmbassyDropDown from "./EmabassyDropDown";

export default function Dashboard() {
  //const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_ENDPOINT =
    "https://apps.lasalle.edu.sg/ProofofStudentStatusLetterAPI/api/StudMgtSys"; //prod

  //const API_ENDPOINT = "https://localhost:44384/api/StudMgtSys";
  // const API_ENDPOINT =
  //   "https://appsuat.lasalle.edu.sg/ProofofStudentStatusLetterAPI/api/StudMgtSys";

  const [content, setContent] = useState(null);
  const [data, setData] = useState(null);
  const [message, setMessage] = useState(null);
  const [studentID, setStudentID] = useState(null);
  const [studentName, setStudentName] = useState(null);
  const [templateName, setTemplateName] = useState(null);
  const { currentUser } = useAuth();
  const history = useHistory();
  const { logout, googleLogout } = useAuth();
  const [destination, setDestination] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emabssyAddress, setEmbassyAddress] = useState(null);

  let token;
  let loginemailraw;
  let loginemail;
  let imageurl;

  token = currentUser.Aa; //Access Token
  loginemailraw = currentUser.email.split("@")[0]; //Signature image Name
  loginemail = loginemailraw.replace(".", "_");
  imageurl = "images/signature/" + loginemail + ".png"; // Signature image url

  let ugroup = localStorage.getItem("usergroupfromlocal");

  useEffect(function () {
    let mounted = true;

    if (ugroup == "" || ugroup == null) {
      ugroup = 0;
    } else {
      axios
        .get(
          // `https://appsuat.lasalle.edu.sg/StatusReportAPI/api/StudMgtSys/ReportTemplate`,
          // `${API_ENDPOINT}/ReportTemplate`,
          `${API_ENDPOINT}/ReportTemplate/SearchTemplatebyUserGroup?usergroup_id=` +
            ugroup,
          {
            headers: {
              Authorization: `Bearer  ${token} `,
            },
          }
        )
        .then((res) => {
          if (mounted) {
            setData(res.data);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            //redirect to logout in reinitiate user session
            googleLogout();
            history.push("/login");
          } else {
            console.log(error.response);
            setMessage("Error calling API");
          }
        });
      return () => (mounted = false);
    }
  }, []);

  const handleClick = () => {
    let baseurl =
      // "https://appsuat.lasalle.edu.sg/StatusReportAPI/api/StudMgtSys/Report/GenerateReport?";
      //"${API_ENDPOINT}/Report/GenerateReport?";
      `${API_ENDPOINT}/Report/GenerateReport?`;

    let id = studentID;
    let sname = studentName;
    let tname = templateName;

    let inclusiveofdate = false;
    let desti = "";
    let fromDT = null;
    let toDT = null;

    if (
      id === "" ||
      id === null ||
      tname === "" ||
      tname === "0" ||
      tname === null
    ) {
      setMessage("Error: Missing Student ID or Template.");
    } else {
      setMessage("");
      setContent("");
      //Travel Template API

      if (tname.toLowerCase().includes("travel")) {
        if (emabssyAddress) {
          desti = emabssyAddress;
          inclusiveofdate = checked;

          let startd = new Date(startDate);
          fromDT =
            startd.getFullYear() +
            "-" +
            (+startd.getMonth() + 1) +
            "-" +
            startd.getDate();

          let endd = new Date(endDate);

          toDT =
            endd.getFullYear() +
            "-" +
            (+endd.getMonth() + 1) +
            "-" +
            endd.getDate();

          baseurl =
            baseurl +
            "id=" +
            id +
            "&templatename=" +
            tname +
            "&destination=" +
            emabssyAddress +
            "&fromDate=" +
            fromDT +
            "&toDate=" +
            toDT +
            "&inclusiveofDate=" +
            inclusiveofdate;
        } else {
          setMessage("Error: Missing Destination.");
          return false;
        }
      } else if (
        tname.toLowerCase().includes("volunteer") ||
        tname.toLowerCase().includes("representative")
      ) {
        let startd = new Date(startDate);
        fromDT =
          startd.getFullYear() +
          "-" +
          (+startd.getMonth() + 1) +
          "-" +
          startd.getDate();

        let endd = new Date(endDate);

        toDT =
          endd.getFullYear() +
          "-" +
          (+endd.getMonth() + 1) +
          "-" +
          endd.getDate();

        baseurl =
          baseurl +
          "id=" +
          id +
          "&templatename=" +
          tname +
          "&fromDate=" +
          fromDT +
          "&toDate=" +
          toDT;
      } else {
        //Normal Template API
        baseurl = baseurl + "id=" + id + "&templatename=" + tname;
      }

      setLoading(true);

      axios
        .get(baseurl, {
          headers: {
            Authorization: `Bearer  ${token} `,
          },
        })
        .then((res) => {
          const data = res.data;

          if (data === null || data === "") {
            setLoading(false);
            setMessage("Error : Student ID Not Found.");
          } else {
            data.templateContent = data.templateContent.replace(
              "{{Signature}}",
              "<img src='" + imageurl + "' width='100px' height='50px'/>"
            );

            setContent(data);
            setLoading(false);
          }

          //console.log("data " + data.templateContent);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            //redirect to logout in reinitiate user session
            googleLogout();
            history.push("/login");
          } else {
            console.log(error.response);
            setMessage("Error calling API");
          }
        });
    }
  };

  const childToParentEmbassy = (childdata) => {
    setEmbassyAddress(childdata);
  };

  //Print

  const printDocument = (templateContent, templateName) => {
    var data = templateContent;
    data =
      data +
      '<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>';
    data = data + '<script src="custom/printcustom.js"></script>';

    var name = templateName;

    var new_page = window.open();
    // new_page.document.write(
    //   "<style>body { height: 100vh ; font-family: 'Arial', 'Helvetica', 'sans-serif'; background-image: url(images/lasalle-letterhead.png); background-size: 100%; background-repeat: repeat; } p{padding: 0; margin:0}; }}</style>"
    // );
    new_page.document.write(
      "<head><link rel='stylesheet' href='custom/printcustom.css' /></head><style>body {font-family: 'Arial', 'Helvetica', 'sans-serif';}</style>"
    );

    new_page.document.write(
      data +
        "<script>document.title = '" +
        name +
        ".pdf';setTimeout(window.print,3000); setTimeout(window.close,5000); $('body').append('<div class=printoverlay>Printing......</div>')</script>"
    );

    // new_page.document.write(
    //   data +
    //     "<script>document.title = '" +
    //     name +
    //     ".pdf'; $('body').append()</script>"
    // );
  };
  //setTimeout(function() { your_func(); }, 5000);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="card-header">
            <h4 className="text-center mb-2">Search Student</h4>
          </div>
          <form className="mt-4">
            <label>
              Student ID*:
              <input name="id" onChange={(e) => setStudentID(e.target.value)} />
            </label>

            <label>
              Student Name:
              <input
                name="name"
                onChange={(e) => setStudentName(e.target.value)}
              />
            </label>

            <label>Template*:</label>
            <select
              className="form-control"
              onChange={(e) => {
                setTemplateName(e.target.value);
                //Toggle travel, volunteer, student rep classes
                if (e.target.value.toLowerCase().includes("travel") === true) {
                  document.getElementsByClassName("travel")[0].style.display =
                    "block";
                  document.getElementsByClassName(
                    "volunteer"
                  )[0].style.display = "none";
                  document.getElementsByClassName(
                    "representative"
                  )[0].style.display = "none";
                } else if (
                  e.target.value.toLowerCase().includes("volunteer") === true
                ) {
                  document.getElementsByClassName(
                    "volunteer"
                  )[0].style.display = "block";
                  document.getElementsByClassName(
                    "representative"
                  )[0].style.display = "none";
                  document.getElementsByClassName("travel")[0].style.display =
                    "none";
                } else if (
                  e.target.value.toLowerCase().includes("representative") ===
                  true
                ) {
                  document.getElementsByClassName(
                    "representative"
                  )[0].style.display = "block";
                  document.getElementsByClassName(
                    "volunteer"
                  )[0].style.display = "none";
                  document.getElementsByClassName("travel")[0].style.display =
                    "none";
                } else {
                  document.getElementsByClassName("travel")[0].style.display =
                    "none";
                  document.getElementsByClassName(
                    "volunteer"
                  )[0].style.display = "none";
                  document.getElementsByClassName(
                    "representative"
                  )[0].style.display = "none";
                }
              }}
            >
              <option value="0"> ---- Select Template ----</option>
              {data &&
                data.map((item) => (
                  <option key={item.systemId} value={item.templateName}>
                    {item.templateName}
                  </option>
                ))}
            </select>
            <br />
            <div className="row travel">
              <label>
                Destination*:
                {/* <input
                  type="text"
                  name="destination"
                  onChange={(e) => setDestination(e.target.value)}
                /> */}
                <EmbassyDropDown childToParentEmbassy={childToParentEmbassy} />
              </label>
              <label>
                From*:
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </label>
              <label>
                To*:
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              </label>
              <label>
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="inclusive both dates"
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                  <label className="form-check-label">
                    &nbsp;Inclusive both dates
                  </label>
                </div>
              </label>
              {/* <label>
                Inclusive both dates:
                <input
                  type="checkbox"
                  name="inclusive both dates"
                  onChange={(e) => setChecked(e.target.checked)}
                />
              </label> */}
            </div>
            <div className="row volunteer">
              <label>
                From*:
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showYearPicker
                  dateFormat="yyyy"
                  yearItemNumber={9}
                />
              </label>
              <label>
                To*:
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  showYearPicker
                  dateFormat="yyyy"
                  yearItemNumber={9}
                />
              </label>
            </div>

            <div className="row representative">
              <label>
                From:
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showYearPicker
                  dateFormat="yyyy"
                  yearItemNumber={9}
                />
              </label>
              <label>
                To:
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  showYearPicker
                  dateFormat="yyyy"
                  yearItemNumber={9}
                />
              </label>
            </div>

            <button type="button" onClick={handleClick}>
              Search
            </button>
            <br />
            <button
              type="button"
              disabled={!content}
              onClick={() =>
                printDocument(content.templateContent, content.templateName)
              }
            >
              Print
            </button>

            <br />
            {message && message.includes("Error") ? (
              <div className="message">
                {" "}
                <div style={{ color: "red", fontWeight: "bold" }}>
                  {message}{" "}
                </div>
              </div>
            ) : (
              <div className="message">{message}</div>
            )}
          </form>
        </div>
        <div className="col-md-8">
          <div className="card-header">
            <h4 className="text-center mb-2">
              Preview {templateName && " - " + templateName}{" "}
            </h4>
          </div>
          <div className="mt-4">
            {loading
              ? "loading...."
              : content && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content.templateContent,
                    }}
                  ></div>
                )}
          </div>
        </div>
      </div>
    </div>
  );
}
